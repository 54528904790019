import {
  Anchor,
  Button,
  Checkbox,
  createStyles,
  Group,
  HoverCard,
  Modal,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  TextInputProps,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';

import {
  useCreateDeviceModelWidgetsSection,
  useUpdateDeviceModelWidgetsSection,
} from '@portals/api/partners';
import { DeviceWidgetsSectionType } from '@portals/device-widgets';
import { ModalProps } from '@portals/framework';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

export const defaultCreateSectionInitialValues = {
  name: '',
  path: '',
  isLabOnly: false,
};

enum SectionConditionType {
  AllDevices = 'all_devices',
  Condition = 'condition',
}

export interface CreateOrEditDeviceWidgetsSectionModalProps
  extends ModalProps<{
    modelId: string;
    section?: DeviceWidgetsSectionType;
    initialValues?: typeof defaultCreateSectionInitialValues;
    openAccordion: (sectionId: string) => void;
  }> {}

export function CreateOrEditDeviceWidgetsSectionModal({
  closeMe,
  data,
}: CreateOrEditDeviceWidgetsSectionModalProps) {
  const { modelId, section, initialValues, openAccordion } = data;

  const isEditMode = Boolean(section && initialValues);

  const [selectedSectionConditionType, setSelectedSectionConditionType] =
    useState<SectionConditionType>(
      initialValues?.path
        ? SectionConditionType.Condition
        : SectionConditionType.AllDevices
    );

  const { classes, theme } = useStyles();

  const form = useForm({
    initialValues: initialValues || defaultCreateSectionInitialValues,
  });

  const createDeviceModelWidgetsSection = useCreateDeviceModelWidgetsSection({
    modelId,
  });

  const updateDeviceModelWidgetsSection = useUpdateDeviceModelWidgetsSection({
    modelId,
    sectionId: section?.id,
  });

  const handleSubmit = ({ name, path, isLabOnly }: typeof form.values) => {
    const conditionPath =
      selectedSectionConditionType === SectionConditionType.Condition
        ? path
        : undefined;

    if (isEditMode) {
      updateDeviceModelWidgetsSection.mutate(
        {
          name,
          condition: {
            path: conditionPath,
            isLabOnly,
          },
        },
        {
          onSuccess: (section) => {
            closeMe();

            !isEditMode && openAccordion(section.id);
          },
        }
      );
      return;
    }

    createDeviceModelWidgetsSection.mutate(
      {
        name,
        condition: { path: conditionPath, isLabOnly },
      },
      { onSuccess: closeMe }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      size={645}
      padding="xxl"
      closeOnClickOutside={false}
      title={section ? `Edit ${section.name}` : 'Add new section'}
    >
      <Text color="primary" my="md">
        Sections are used to group widgets, and can be set to appear under
        certain conditions. For example, you can create a section only visible
        to devices with a certain feature or command.
      </Text>

      <form onSubmit={form.onSubmit(handleSubmit)} className={classes.form}>
        <TextInput
          label="Section name"
          placeholder="Section name"
          required
          data-autofocus
          styles={textInputStyles}
          {...form.getInputProps('name')}
          data-testid="section-name-input"
        />

        <Stack spacing="xs">
          <Group spacing={4}>
            <Text color="gray.6">Condition type</Text>
          </Group>

          <Stack>
            <SegmentedControl
              value={selectedSectionConditionType}
              onChange={(value) =>
                setSelectedSectionConditionType(value as SectionConditionType)
              }
              data={[
                {
                  label: 'All devices',
                  value: SectionConditionType.AllDevices,
                },
                {
                  label: 'Configuration or Details',
                  value: SectionConditionType.Condition,
                },
              ]}
              data-testid="condition-type-button"
              w={340}
            />

            {selectedSectionConditionType === 'condition' && (
              <TextInput
                styles={textInputStyles}
                label={
                  <>
                    <Text>Path</Text>
                    <HoverCard
                      width={250}
                      shadow="md"
                      position="top"
                      withArrow
                      arrowSize={8}
                    >
                      <HoverCard.Target>
                        <QuestionCircle
                          width={16}
                          height={16}
                          color={theme.colors.gray[6]}
                        />
                      </HoverCard.Target>

                      <HoverCard.Dropdown>
                        Sections can be visible to all devices, or limited to
                        devices with certain properties based on their
                        configuration or details. To learn more about
                        configuration or details, click{' '}
                        <Anchor
                          href="https://dev.xyte.io/reference/data-types"
                          target="_blank"
                        >
                          here
                        </Anchor>
                        .
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </>
                }
                placeholder="config.settings.surroundSound.enabled"
                required
                {...form.getInputProps('path')}
                data-testid="configuration-path-input"
              />
            )}
          </Stack>
        </Stack>

        <Checkbox
          styles={textInputStyles}
          label={
            <Group spacing={4}>
              <Text>Make this section seen only for lab accounts</Text>
              <Tooltip
                width={300}
                multiline
                withArrow
                label="Limit sections to Lab accounts, so you can test widgets on a published model before making it visible for all devices."
              >
                <QuestionCircle
                  width={16}
                  height={16}
                  color={theme.colors.gray[6]}
                />
              </Tooltip>
            </Group>
          }
          data-testid="lab-accounts-checkbox"
          {...form.getInputProps('isLabOnly', { type: 'checkbox' })}
        />

        <Group position="right" mt="xl">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button type="submit" data-testid="add-section-submit-button">
            {section ? 'Save' : 'Add section'}
          </Button>
        </Group>
      </form>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xl,
  },
}));

const textInputStyles: TextInputProps['styles'] = (theme) => ({
  label: {
    color: theme.colors.gray[6],
    display: 'flex',
    gap: 4,
  },
});
