import { Button, Group, Modal, Text } from '@mantine/core';
import React from 'react';

import { useRemoveDeviceModelWidgetsSection } from '@portals/api/partners';
import {
  DeviceWidgetsSectionType,
  WidgetWithLayout,
} from '@portals/device-widgets';
import { ModalProps } from '@portals/framework';

export interface RemoveDeviceWidgetsSectionModalProps
  extends ModalProps<{
    modelId: string;
    section: DeviceWidgetsSectionType<WidgetWithLayout>;
  }> {}

export function RemoveDeviceWidgetsSectionModal({
  closeMe,
  data,
}: RemoveDeviceWidgetsSectionModalProps) {
  const { modelId, section } = data;
  const hasWidgets = section.widgets.length > 0;
  const removeDeviceModelWidgetsSection = useRemoveDeviceModelWidgetsSection({
    modelId,
    sectionId: section.id,
  });

  const handleRemoveSection = () => {
    removeDeviceModelWidgetsSection.mutate(undefined, { onSuccess: closeMe });
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      size="lg"
      padding="xxl"
      title={
        hasWidgets ? `Can't remove ${section.name}` : `Remove ${section.name}`
      }
      closeOnClickOutside={false}
    >
      <Text color="primary">
        {hasWidgets ? (
          `Delete all widgets from ${section.name} before removing it.`
        ) : (
          <>
            <Text mb="xs">
              Remove this section will also remove all of its widgets from the
              device section. This action cannot be undone.
            </Text>
            <Text>Are you sure you want to proceed?</Text>
          </>
        )}
      </Text>
      <Group position="right" mt="xl">
        {hasWidgets ? (
          <>
            <Button onClick={closeMe} data-testid="got-it-button">
              Got it
            </Button>
          </>
        ) : (
          <>
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>

            <Button
              color="red.4"
              onClick={handleRemoveSection}
              data-testid="remove-section-button"
            >
              Remove section
            </Button>
          </>
        )}
      </Group>
    </Modal>
  );
}
