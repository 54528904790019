import { Anchor, Text } from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';

import { AuditLogTemplates, AuditLogType } from '@portals/types';

import { DASHBOARD_PATHS } from '../../../routes/dashboard/dashboard-paths.constants';

interface DeviceModelWidgetsSectionLogsProps {
  auditLog: AuditLogType;
}

export const DeviceModelWidgetsSectionLogs = ({
  auditLog,
}: DeviceModelWidgetsSectionLogsProps) => {
  const auditLogParams = keyBy('name', auditLog.params);

  const deviceModelParam = auditLogParams.device_model;
  const sectionParam = auditLogParams.section_name;

  return (
    <Text size="sm">
      {logMessages[auditLog.template as SectionRelatedAuditLogTemplates]}{' '}
      {sectionParam.value} on {deviceModelParam.model}:{' '}
      <Anchor
        size="sm"
        component={Link}
        to={DASHBOARD_PATHS.dynamicPaths.deviceModel(deviceModelParam.id || '')}
      >
        {deviceModelParam.display_name}
      </Anchor>
    </Text>
  );
};

type SectionRelatedAuditLogTemplates = Extract<
  AuditLogTemplates,
  | 'section_created'
  | 'section_deleted'
  | 'section_edited'
  | 'section_duplicated'
>;

const logMessages: Record<SectionRelatedAuditLogTemplates, string> = {
  section_created: 'Created dashboard section',
  section_deleted: 'Deleted dashboard section',
  section_edited: 'Edited dashboard section',
  section_duplicated: 'Duplicated dashboard section',
};
