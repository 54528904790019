import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Pendo } from '@portals/analytics';
import { useFeatureFlags } from '@portals/api/partners';
import { useCurrentUser } from '@portals/api/ui';
import { useAuth } from '@portals/redux';

import { usePricingPlan } from './partner-config';

export function useAnalytics() {
  const auth = useAuth();
  const pricingPlan = usePricingPlan();
  const featureFlags = useFeatureFlags();
  const currentUser = useCurrentUser();

  const location = useLocation();
  const isPendoReady = Pendo.isReady();

  useEffect(
    function trackFeatureFlags() {
      if (featureFlags) {
        Pendo.track('featureFlags', featureFlags);
      }
    },
    [featureFlags]
  );

  useEffect(
    function trackScreenResolution() {
      if (isPendoReady) {
        Pendo.track(`Screen resolution (v1)`, {
          width: window.screen.availWidth,
          height: window.screen.availHeight,
        });
      }
    },
    [isPendoReady]
  );

  useEffect(
    function configurePendo() {
      // Pendo.initialize() was called already
      if (isPendoReady) {
        if (auth && Pendo.isAnonymousVisitor() && pricingPlan?.name) {
          // User is logged in, but Pendo visitor is still set to anonymous
          Pendo.identify({
            visitor: {
              id: auth.email,
              firstName: auth.name?.split(' ')?.[0],
            },
            account: {
              id: auth.tenant?.display_name,
              support_email: 'support@xyte.io',
              pricing_plan: pricingPlan.name,
              created_at: currentUser.data?.created_at,
            },
          });
        }
      } else {
        // Pendo.initialize() wasn't called yet
        if (auth && pricingPlan?.name) {
          // Pendo is not ready, initializing with authenticated visitor data
          Pendo.initializeAuthenticated({
            visitor: {
              id: auth.email,
              firstName: auth.name?.split(' ')?.[0],
            },
            account: {
              id: auth.tenant?.display_name,
              support_email: 'support@xyte.io',
              pricing_plan: pricingPlan.name,
            },
          });
        } else {
          // Do not initialize pendo on sign-in page
          if (location.pathname.includes('/auth/sign-in')) return;

          // Pendo is not ready, initializing with anonymous visitor data
          Pendo.initializeAnonymous();
        }
      }
    },
    [
      isPendoReady,
      auth,
      location.pathname,
      pricingPlan?.name,
      currentUser.data?.created_at,
    ]
  );
}
