import { Button, createStyles, Group, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { useDuplicateDeviceModelWidgetsSection } from '@portals/api/partners';
import {
  DeviceWidgetsSectionType,
  WidgetWithLayout,
} from '@portals/device-widgets';
import { ModalProps } from '@portals/framework';

import { defaultCreateSectionInitialValues } from './CreateOrEditDeviceWidgetsSectionModal';

export interface DuplicateDeviceWidgetsSectionModalProps
  extends ModalProps<{
    modelId: string;
    originalSectionName: string;
    section: DeviceWidgetsSectionType<WidgetWithLayout>;
    initialValues: { name: (typeof defaultCreateSectionInitialValues)['name'] };
    openAccordion: (sectionId: string) => void;
  }> {}

export function DuplicateDeviceWidgetsSectionModal({
  closeMe,
  data,
}: DuplicateDeviceWidgetsSectionModalProps) {
  const {
    section,
    modelId,
    originalSectionName,
    initialValues,
    openAccordion,
  } = data;

  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      name: `${initialValues.name}`,
    },
  });

  const duplicateDeviceModelWidgetsSection =
    useDuplicateDeviceModelWidgetsSection({
      modelId,
      sectionId: section.id,
    });

  const handleSubmit = ({ name }: typeof form.values) =>
    duplicateDeviceModelWidgetsSection.mutate(
      {
        name,
      },
      {
        onSuccess: (section) => {
          closeMe();
          openAccordion(section.id);
        },
      }
    );

  return (
    <Modal
      opened
      onClose={closeMe}
      size={645}
      padding="xxl"
      title={`Duplicate ${originalSectionName}`}
      closeOnClickOutside={false}
    >
      <form onSubmit={form.onSubmit(handleSubmit)} className={classes.form}>
        <TextInput
          label="Section name"
          placeholder="Enter section name"
          required
          data-autofocus
          {...form.getInputProps('name')}
        />

        <Group position="right" mt="xl">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button type="submit">Duplicate section</Button>
        </Group>
      </form>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xl,
  },
}));
