import { UsePaginatedTableApiQuery } from '@portals/types';

import {
  ANALYTICS_DASHBOARD_CLAIMED_DEVICES_RATE_API_URL,
  ANALYTICS_DASHBOARD_CUSTOMERS_API_URL,
  ANALYTICS_DASHBOARD_CUSTOMERS_CREATION_RATE_API_URL,
  ANALYTICS_DASHBOARD_STATS_API_URL,
  analyticsDashboardQueryKeys,
} from './analytics-dashboard.constants';
import {
  AnalyticsCustomersResponse,
  ClaimedDeviceRateType,
  CustomerCreationRateType,
  StatsType,
} from './analytics-dashboard.types';
import { useApiQuery } from '../../hooks';
import { usePaginatedTableApiQuery } from '../../utils';

export function useAnalyticsStats() {
  return useApiQuery<StatsType>(
    ANALYTICS_DASHBOARD_STATS_API_URL,
    analyticsDashboardQueryKeys.stats()
  );
}

export function useCustomersAnalyticsTable(
  tableState: UsePaginatedTableApiQuery<AnalyticsCustomersResponse>['tableState'],
  columns: UsePaginatedTableApiQuery<AnalyticsCustomersResponse>['columns']
) {
  return usePaginatedTableApiQuery<AnalyticsCustomersResponse>({
    baseUrl: ANALYTICS_DASHBOARD_CUSTOMERS_API_URL,
    queryKey: [...analyticsDashboardQueryKeys.customers(), tableState],
    tableState,
    columns,
    queryOptions: {
      staleTime: 0,
    },
  });
}

export function useAnalyticsCustomerCreationRate() {
  return useApiQuery<CustomerCreationRateType>(
    ANALYTICS_DASHBOARD_CUSTOMERS_CREATION_RATE_API_URL,
    analyticsDashboardQueryKeys.customerCreationRate()
  );
}

export function useAnalyticsClaimedDeviceRate() {
  return useApiQuery<ClaimedDeviceRateType>(
    ANALYTICS_DASHBOARD_CLAIMED_DEVICES_RATE_API_URL,
    analyticsDashboardQueryKeys.claimedDeviceRate()
  );
}
