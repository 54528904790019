import { Anchor, Text } from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';

import { AuditLogTemplates, AuditLogType } from '@portals/types';

import { DASHBOARD_PATHS } from '../../../routes/dashboard/dashboard-paths.constants';

interface DeviceModelWidgetLogsProps {
  auditLog: AuditLogType;
}

export const DeviceModelWidgetLogs = ({
  auditLog,
}: DeviceModelWidgetLogsProps) => {
  const auditLogParams = keyBy('name', auditLog.params);

  const deviceModelParam = auditLogParams.device_model;
  const sectionParam = auditLogParams.section_name;
  const widgetParam = auditLogParams.widget_name;

  return (
    <Text size="sm">
      {logMessages[auditLog.template as SectionRelatedAuditLogTemplates]}{' '}
      {widgetParam.value} under {sectionParam.value} on {deviceModelParam.model}
      :{' '}
      <Anchor
        size="sm"
        component={Link}
        to={DASHBOARD_PATHS.dynamicPaths.deviceModel(deviceModelParam.id || '')}
      >
        {deviceModelParam.display_name}
      </Anchor>
    </Text>
  );
};

type SectionRelatedAuditLogTemplates = Extract<
  AuditLogTemplates,
  'widget_created' | 'widget_deleted' | 'widget_edited' | 'widget_moved'
>;

const logMessages: Record<SectionRelatedAuditLogTemplates, string> = {
  widget_created: 'Created widget',
  widget_deleted: 'Deleted widget',
  widget_edited: 'Edited widget',
  widget_moved: 'Moved widget',
};
